import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import lawyerService from "../services/lawyerService"
import React, { useEffect, useState } from "react"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"

const LawyerList = () => {
  const navigate = useNavigate()
  const [lawyerData, setLawyer] = useState([])
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [lawyer_schoolData, setLawyer_schoolData] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const fetchAll = async () => {
    try {
      setLoading(true)
      await Promise.all([
        fetchLawyerList(),
        fetchLawyer_schoolList(),
        fetchUserList(),
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchLawyerList = async (pageNumber = 1) => {
    try {
      const response = await lawyerService.getLawyerListPaginated(pageNumber)
      setLawyer(response.results)
      setCount(response.count)
    } catch (error) {
      console.log("Error fetching lawyer list:", error)
    }
  }

  const pageLawyerList = async (pageNumber = 1, filters = {}) => {
    try {
      setLoading(true)
      const response = await lawyerService.getLawyerListPaginated(
        pageNumber,
        filters
      )
      setLawyer(response.results)
      setCount(response.count)
      setPage(pageNumber)
    } catch (error) {
      console.log("Error fetching lawyer list:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchLawyer_schoolList = async () => {
    try {
      const response = await lawyerService.getLawyer_schoolList()
      setLawyer_schoolData(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const fetchUserList = async () => {
    try {
      const response = await lawyerService.getUserList()
      setUserData(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  useEffect(() => {
    fetchAll()
  }, [])

  const handleDeleteLawyer = async (lawyerId) => {
    try {
      setLoading(true)
      await lawyerService.deleteLawyer(lawyerId)
      const data = lawyerData.filter((rows) => rows.id != lawyerId)
      setLawyer(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lawyer:", error)
      setLoading(false)
    }
  }

  const handleCreateLawyer = () => {
    navigate("/lawyer/new")
  }

  const handleLawyerExcelExport = async () => {
    try {
      setLoading(true)
      const data = await lawyerService.getLawyerExcel({
        responseType: "blob",
      })
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      )
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "abogados.xlsx")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setLoading(false)
    } catch (error) {
      console.error("Error exporting lawyer list:", error)
      setLoading(false)
    }
  }

  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ]

  const formatDate = (date) => {
    if (!date) return ""
    const [year, month, day] = date.split("-")
    return `${day} de ${months[parseInt(month, 10) - 1]} de ${year}`
  }

  const lawyerColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "user",
      selector: (row) => {
        const user = userData.find((user) => user.id === row.user)
        return user ? user.name : ""
      },
      sortable: true,
      cell: (row) => {
        const user = userData.find((user) => user.id === row.user)
        return user ? user.name : ""
      },
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "last_name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "business_name",
      selector: (row) => row.business_name,
      sortable: true,
    },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "image",
      selector: (row) => row.image,
      sortable: true,
    },
    {
      name: "locality",
      selector: (row) => row.locality,
      sortable: true,
    },
    {
      name: "business_direction",
      selector: (row) => row.business_direction,
      sortable: true,
    },
    {
      name: "business_name",
      selector: (row) => row.business_name,
      sortable: true,
    },
    {
      name: "inicio vacaciones",
      selector: (row) => {
        return row.date_start_vacation
          ? formatDate(row.date_start_vacation)
          : "-"
      },
      sortable: true,
    },
    {
      name: "fin vacaciones",
      selector: (row) => {
        return row.date_end_vacation ? formatDate(row.date_end_vacation) : "-"
      },
      sortable: true,
    },
    {
      name: "lawyer_school",
      selector: (row) => {
        const lawyer_school = lawyer_schoolData.find(
          (lawyer_school) => lawyer_school.id === row.lawyer_school
        )
        return lawyer_school ? lawyer_school.name : ""
      },
      sortable: true,
      cell: (row) => {
        const lawyer_school = lawyer_schoolData.find(
          (lawyer_school) => lawyer_school.id === row.lawyer_school
        )
        return lawyer_school ? lawyer_school.name : ""
      },
    },
    {
      name: "lawyer_school_code",
      selector: (row) => row.lawyer_school_code,
      sortable: true,
    },
    // {
    //   name: "about_me",
    //   selector: (row) => row.about_me,
    //   sortable: true,
    // },
    {
      name: "complete_later",
      selector: (row) => {
        if (row.complete_later) {
          return <Badge bg="warning">Yes</Badge>
        } else {
          return <Badge bg="success">No</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "Artículos patrocinados",
      selector: (row) => {
        if (row?.posts_subscription?.posts_plan?.name) {
          return (
            <Badge bg="success">
              {row?.posts_subscription?.posts_plan?.name}
            </Badge>
          )
        } else {
          return <Badge bg="warning">Desactivado</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "Presencia básica en directorio",
      selector: (row) => {
        if (row?.default_directory_visibility) {
          return <Badge bg="success">Activado</Badge>
        } else {
          return <Badge bg="warning">Desactivado</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link to={`/lawyer/${row.id}`} className="dropdown-item">
                    <Icon name="user" />
                    <span>Perfil</span>
                  </Link>
                  <Link to={`/lawyer/edit/${row.id}`} className="dropdown-item">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLawyer(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Abogados" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Abogados</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Abogados
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex gap-2">
              <li>
                <Button
                  size="md"
                  variant="primary"
                  onClick={handleLawyerExcelExport}
                >
                  <Icon name="plus" />
                  <span>Exportar Abogados</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLawyer}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              {/* <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLawyer}
                >
                  <Icon name="plus" />
                  <span>Añadir abogado</span>
                </Button>
              </li> */}
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={lawyerData}
              columns={lawyerColumns}
              expandableRows
              actions
              searchByList={[
                "name",
                "last_name",
                "email",
                "phone_number",
                "nif",
                "business_name",
              ]}
              backEndPagination={true}
              fetchPaginatedData={pageLawyerList}
              pageCount={count}
              currentPaginationPage={page}
              backendFilter={true}
              filterFunc={pageLawyerList}
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default LawyerList
